<template>
  <div class="iotDeviceForm">
    <form-panel
      ref="formPanel"
      v-bind="submitConfig"
      :form="form"
      :submitBefore="submitBefore"
      :submitSuccess="submitSuccess"
      @update="update"
    >
      <div class="iotDeviceForm-body">
        <div class="iotDeviceForm-t">
          <v-steps :stepData="stepData" :active="active"></v-steps>
        </div>
        <div class="iotDeviceForm-b">
          <div class="iotDeviceForm-b-l" v-if="active == 0">
            <el-form-item
              label="选择园区"
              :rules="[
                { required: true, message: '请选择园区', trigger: 'blur' },
              ]"
              prop="spaceId"
            >
              <community-select
                v-model="form.spaceId"
                specifyCollectionTypes=""
                spaceTypes="10,30"
              />
            </el-form-item>
            <el-form-item
              label="名称"
              :rules="[
                { required: true, message: '请输入名称', trigger: 'blur' },
              ]"
              prop="deviceName"
            >
              <v-input
                placeholder="请输入名称"
                v-model="form.deviceName"
                :width="250"
                :maxlength="300"
              ></v-input>
            </el-form-item>
            <el-form-item
              label="所属产品"
              :rules="[
                { required: true, message: '请选择产品', trigger: 'blur' },
              ]"
              prop="productId"
            >
              <v-select
                clearable
                filterable
                :options="proList"
                v-model="form.productId"
              />
            </el-form-item>
            <el-form-item
              label="设备唯一编号"
              :rules="[
                {
                  required: true,
                  message: '请输入设备唯一编号',
                  trigger: 'blur',
                },
              ]"
              prop="deviceNum"
            >
              <v-input
                placeholder="请输入设备唯一编号"
                v-model="form.deviceNum"
                :width="250"
                :maxlength="500"
              ></v-input>
            </el-form-item>
            <div class="title">扩展信息</div>
            <el-form-item
              label="坐标信息"
              :rules="[
                {
                  required: true,
                  message: '请输入坐标信息',
                  trigger: 'blur',
                },
              ]"
              prop="extra"
            >
              <span class="label">经度</span>
              <v-input
                placeholder="请输入经度"
                v-model="form.extra.longitude"
                :width="250"
                :maxlength="500"
              ></v-input>
              <span class="label">纬度</span>
              <v-input
                placeholder="请输入纬度"
                v-model="form.extra.latitude"
                :width="250"
                :maxlength="500"
              ></v-input>
              <v-button text="地图标注" @click="showMap"></v-button>
            </el-form-item>
            <el-form-item
              label="安装区域"
              :rules="[
                {
                  required: true,
                  message: '请输入安装区域',
                  trigger: 'blur',
                },
              ]"
              prop="extra"
            >
              <v-input
                clearable
                placeholder="请输入安装区域"
                v-model="form.extra.area"
                :width="250"
                :maxlength="500"
              ></v-input>
            </el-form-item>
            <el-form-item
              label="IP地址"
              :rules="[
                {
                  required: true,
                  message: '请输入IP地址',
                  trigger: 'blur',
                },
              ]"
              prop="extra"
            >
              <v-input
                clearable
                placeholder="请输入IP地址"
                v-model="form.extra.ip"
                :width="250"
                :maxlength="500"
              ></v-input>
            </el-form-item>
            <el-form-item
              label="备注"
              :rules="[
                {
                  required: true,
                  message: '请输入备注',
                  trigger: 'blur',
                },
              ]"
              prop="extra"
            >
              <v-input
                clearable
                type="textarea"
                placeholder="请输入备注"
                v-model="form.extra.remark"
                :width="250"
                :maxlength="10000"
              ></v-input>
            </el-form-item>
          </div>
          <div class="iotDeviceForm-b-r" v-if="active == 1">
            <el-form-item
              label="设备凭证信息"
              :rules="[
                {
                  required: false,
                  message: '请输入备注',
                  trigger: 'blur',
                },
              ]"
              prop="deviceCredentials"
            >
              <v-input
                disabled
                clearable
                type="textarea"
                v-model="deviceCredentials"
                :width="width"
                :maxlength="10000"
              ></v-input>
            </el-form-item>
          </div>
        </div>
      </div>
      <template #saveBeforeSlot
        ><v-button text="上一步" @click="toBefore" v-if="isBefore"> </v-button>
        <v-button text="关闭" @click="closeForm" v-if="isBefore"> </v-button>
      </template>
    </form-panel>
    <v-map
      :selectedLng="Number(form.longitude)"
      :selectedLat="Number(form.latitude)"
      :showMapDialog.sync="showMapDialog"
      @setLngAndLat="setLngAndLat"
    ></v-map>
  </div>
</template>

<script>
import {
  addDeviceList,
  getDeviceCredentials,
  getDeviceProList,
  getDeviceInfoUrl,
} from "./api.js";
import {} from "./map.js";

export default {
  name: "placeEdit",
  data() {
    return {
      width: 450,
      active: 0,
      stepData: [
        { title: "设备详细信息", key: 1 },
        { title: "设备凭证", key: 2 },
      ],
      submitConfig: {
        queryUrl: "",
        submitUrl: addDeviceList,
      },
      proList: [],
      showMapDialog: false,
      isBefore: false,
      deviceCredentials: "",
      form: {
        spaceId: "",
        id: "",
        deviceName: "",
        productId: "",
        deviceNum: "",
        extra: {
          longitude: "",
          latitude: "",
          area: "",
          remark: "",
          ip: "",
        },
      },
    };
  },
  components: {},
  mounted() {
    const { id } = this.$route.query;
    this.form.id = id;
    if (id !== undefined) {
      this.submitConfig.queryUrl = getDeviceInfoUrl({ deviceId: id });
      this.$refs.formPanel.getData({ id: id });
    }
    this.getDevicePro();
    this.$setBreadList(id ? "编辑" : "新增");
  },
  methods: {
    closeForm() {
      this.$router.go(-1);
    },
    toBefore() {
      this.active = 0;
      this.isBefore = false;
      this.submitConfig.queryUrl = getDeviceInfoUrl({ deviceId: this.form.id });
      this.$refs.formPanel.getData({ id: this.form.id });
      this.submitConfig.submitUrl = addDeviceList;
    },
    showMap() {
      this.showMapDialog = true;
    },
    setLngAndLat(l) {
      if (!l.lng || !l.lat) {
        return false;
      }
      this.form.extra.longitude = l.lng;
      this.form.extra.latitude = l.lat;
    },
    async getDevicePro() {
      let params = {};
      this.proList = [];
      let res = await this.$axios.get(`${getDeviceProList}`, { params });
      if (res.code === 200) {
        if (res.data && res.data.records) {
          res.data.records.forEach((ele) => {
            let obj = {};
            obj.label = ele.name;
            obj.value = ele.id;
            this.proList.push(obj);
          });
        }
      }
    },
    update(data) {
      Object.keys(this.form).forEach((key) => {
        this.form[key] = data[key] || this.form[key];
      });
      if (this.form.extra) {
        this.form.extra = JSON.parse(this.form.extra);
      }
    },
    submitBefore() {
      let target = {};
      Object.assign(target, this.form.extra);
      this.form.extra = JSON.stringify(target);
      return true;
    },
    async getDeviceCredentials(data) {
      let params = {};
      this.proList = [];
      let res = await this.$axios.get(
        `${getDeviceCredentials({ deviceId: data })}`,
        {
          params,
        }
      );
      if (res.code === 200) {
        this.deviceCredentials = res.data;
      }
    },
    submitSuccess(data) {
      console.log(data, "===-----");
      this.active = 1;
      this.isBefore = true;

      this.submitConfig.submitUrl = "";
      this.getDeviceCredentials(data);
      return false;
    },
  },
};
</script>
<style lang="less" scoped>
.iotDeviceForm {
  box-sizing: border-box;
  height: 100%;
  /deep/ .el-textarea {
    width: 450px !important;
  }
  .iotDeviceForm-body {
    .iotDeviceForm-b {
      padding: 40px 0;
    }
    .title {
      padding: 10px 40px;
    }
    .label {
      margin: 0 10px;
    }
  }
}
</style>
<style lang="less">
.iotDeviceForm .v-control {
}
</style>
